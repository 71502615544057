import React, { createContext, useState, useContext } from 'react';

// Create a new context
const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState([{ id: 1, isChecked: false, name: "IP40", host: "host", user: "user" }]);
    const [isSupportSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMeetingSidebarOpen, setIsMeetingSidebarOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isSessionClicked, setIsSessionClicked] = useState(false);
    const [index, setIndex] = useState(-1);

    return (
        <SessionContext.Provider value={{ index, setIndex, isSessionClicked, setIsSessionClicked, isSupportSidebarOpen, setIsMeetingSidebarOpen, isMeetingSidebarOpen, setIsSidebarOpen, session, setSession, isChecked, setIsChecked }}>
            {children}
        </SessionContext.Provider>
    );
};

// Custom hook to use the session context
export const useSessionContext = () => useContext(SessionContext);
