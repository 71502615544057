import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const Temp = ({ createSession }) => {
    return (
        <div className="flex justify-center">
            <button onClick={createSession} className="mt-8 mb-2 px-4 py-2 bg-auburn text-white rounded-md shadow-md hover:bg-umber focus:outline-none focus:ring-2 focus:ring-offset-2">
                <span className='mr-2'> Create Session</span>
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </div>
    )
}

