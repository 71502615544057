import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import MainApp from "./MainApp";
import { SessionProvider } from "./context/SessionContext";
import { Signup } from "./components/admin/Signup";
import { Signin } from "./components/admin/Signin";
import { AuthProvider } from "./context/AuthContext";
import Loader from "./components/Loader";

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <SessionProvider>
          <Routes>
//            <Route path="/signup" element={<Signup />} />
  //          <Route path="/signin" element={<Signin />} />
            <Route path="/" element={<MainApp />} />
            {/* <Route path="/loader" element={<Loader />} /> */}
          </Routes>
        </SessionProvider>
      </AuthProvider>
    </Router>
  );
}
