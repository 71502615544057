import { React, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faUsers, faKey, faGear } from '@fortawesome/free-solid-svg-icons';
import { Dashboard } from './Dashboard';
import { SupportSidebar } from './sidebars/SupportSidebar';
import { MeetingSidebar } from './sidebars/MeetingSidebar';
import { useSessionContext } from '../context/SessionContext';

export const Sidebar = () => {

    const { isSupportSidebarOpen, setIsSidebarOpen } = useSessionContext();
    const { isMeetingSidebarOpen, setIsMeetingSidebarOpen } = useSessionContext();

    const toggleSupport = () => {
        setIsSidebarOpen(!isSupportSidebarOpen);
        console.log(isSupportSidebarOpen)
    };
    const toggleMeeting = () => {
        setIsMeetingSidebarOpen(!isMeetingSidebarOpen);
        console.log(isMeetingSidebarOpen)
    };


    return (
        <div>
            <aside id="logo-sidebar" className={`fixed top-0 left-0 z-40 lg:w-36 sm:w-20 h-screen pt-20 transition-transform bg-night border-r `} aria-label="Sidebar">
                <div className="h-full flex flex-col justify-between">
                    <div className="px-3 pb-4 overflow-y-auto bg-night dark:bg-gray-800">
                        <ul className="space-y-2 font-medium">
                            <li>
                                <a href="#" onClick={toggleSupport} className="flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-gray-700 group">
                                    <FontAwesomeIcon icon={faHeadset} />
                                    <span className="ms-3">Support</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={toggleMeeting} className="flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-gray-700 group">
                                    <FontAwesomeIcon icon={faUsers} />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Meeting</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-gray-700 group">
                                    <FontAwesomeIcon icon={faKey} />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Inbox</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="px-3 pb-4 overflow-y-auto bg-night dark:bg-gray-800">
                        <ul className="space-y-2 font-medium">
                            <li>
                                <a href="#" className="flex items-center p-2 bg-night text-white rounded-lg dark:text-white hover:bg-gray-700 group">
                                    <FontAwesomeIcon icon={faGear} />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Admin</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
            

            {isSupportSidebarOpen && <SupportSidebar />}
            {isMeetingSidebarOpen && <MeetingSidebar />}
            {/* <div className="flex-grow">
                <Dashboard />
            </div> */}


        </div>
    )
}
