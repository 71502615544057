
import { React, useState } from 'react';
import { useSessionContext } from '../../context/SessionContext';

export const SupportSidebar = () => {
    const { session,isSupportSidebarOpen } = useSessionContext();
    console.log("sessions",session.length);
    const [activeProperty, setActiveProperty] = useState(null);
    const supportProperties = [
        { name: 'All Sessions', value: session.length },
        { name: 'My Sessions', value: 10 },
        { name: 'Requested Sessions', value: 5 },
        { name: 'Sessions by Tag', value: 15 },
    ]

    const handleClick = (property) => {
        setActiveProperty(property.name);
    };

    return (<>
        {isSupportSidebarOpen && (
            <aside id="logo-sidebar" className={`fixed top-0 left-36 z-40 w-72 h-screen pt-20 transition-transform bg-night border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`} aria-label="Sidebar">
                <div className="h-full flex flex-col justify-between">
                    <div className="px-2 pb-4 overflow-y-auto bg-night dark:bg-gray-800">
                        <div className="px-4 py-1 overflow-y-auto bg-night dark:bg-gray-800">
                            <h2 className="text-xl font-semibold text-white">Support</h2>
                            <p className="mt-4 text-sm text-gray-400">Create support sessions to provide immediate support for any computer on the internet.</p>

                            <div className="mt-12">
                                {supportProperties.map(property => (
                                    <div key={property.name} className={`flex justify-between text-xl items-center py-2 cursor-pointer ${activeProperty === property.name ? 'text-gray-100 font-semibold' : 'text-gray-400'}`} onClick={() => handleClick(property)}>
                                        <span className="text-base">{property.name}</span>
                                        <span className="text-base">{property.value}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        )}
    </>
        // <div>SupportSidebar</div>
    )
}

