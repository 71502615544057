import { React, useState, useEffect } from 'react'
import { useSessionContext } from '../context/SessionContext';

export const Session = ({ onSelect, session, isChecked,idx}) => {
    // const [isChecked, setIsChecked] = useState(false);
    const { isSessionClicked,setIsSessionClicked,setIndex} = useSessionContext();
    const handleCheckboxChange = () => {
        // setIsChecked(!isChecked);
        const updatedSession = { ...session, isChecked: !isChecked };
        onSelect(updatedSession);
        // console.log("updatedSession",updatedSession);
    };

    const handleSessionClick = () => {
        console.log("idx",idx)
        setIndex(idx);
        setIsSessionClicked(true);
        console.log("clicked on session")
    }


    return (
        <div className="bg-white rounded-lg overflow-hidden shadow-sm my-2">
            <div onClick={handleSessionClick} className="py-2 px-4 flex items-center cursor-pointer">
                <div className='flex-row'>
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="form-checkbox h-4 w-4 text-indigo-600" />
                </div>
                <div className='flex-row ml-3'>
                    <h3 className="text-md font-semibold text-gray-800 mb-0">{session.name}</h3>
                    <p className="text-xs text-gray-600">ID: {session.id}</p>
                    <p className="text-xs text-gray-600">Host: {session.host}</p>
                    <p className="text-xs text-gray-600">User: {session.user}</p>
                </div>
            </div>
        </div>

    )
}

