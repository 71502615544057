import React, { useState } from 'react';
import { useSessionContext } from '../../context/SessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export const Delete = ({ selectedSessions, setSelectedSessions, setSelectAll }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { session, setSession } = useSessionContext();
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleDelete = () => {
        setIsModalOpen(false);
        const updatedSessions = session.filter(session => !selectedSessions.includes(session.id));
        setSession(updatedSessions);
        setSelectedSessions([]);

        if (updatedSessions.length === 0) {
            setSelectAll(false); // Untick the "Select All" checkbox
        }
    };

    return (
        <>
            <button onClick={toggleModal} className="px-2 py-1 text-night rounded-md" type="button">
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Delete
            </button>

            {isModalOpen && (
                <div id="popup-modal" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
                        <button onClick={toggleModal} className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 inline-flex justify-center items-center">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="text-center">
                            <svg className="mx-auto mb-4 text-night w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-night">Are you sure you want to delete this product?</h3>
                            <button onClick={handleDelete} className="text-white bg-auburn hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5">
                                Yes, I'm sure
                            </button>
                            <button onClick={toggleModal} className="ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100  px-5 py-2.5">
                                No, cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Delete;
