import React, { useState, useEffect } from 'react';
import './App.css';
import { Navbar } from './components/Navbar';
import { Sidebar } from './components/Sidebar';
import { Dashboard } from './components/Dashboard';
import { SessionProvider, useSessionContext } from './context/SessionContext';
import RightPanel from './components/RightPanel';
import { useAuth } from './context/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from './services/axiosInstance';


function MainApp() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const { authData } = useAuth();

//    useEffect(() => {
  //      const accessToken = getCookie('accessToken');

    //    const accessTokenGenerator = async () => {
      //      try {
        //        const accessResponse = await api.post(
          //          "/api/auth/access",
            //        {},
              //      {
                //        headers: {
                  //          Authorization: `Bearer ${accessToken}`,
                    //    },
     //               }
       //         );
         //       console.log("response access token api", accessResponse);
           //     navigate("/")
    //        } catch (error) {
      //          console.error("Error fetching access token:", error.response.data.message);
        //    }
  //      };
    //    accessTokenGenerator();

//    }, [navigate]);

  //  const getCookie = (name) => {
    //    const cookieString = document.cookie;
      //  const cookies = cookieString.split('; ');
        //for (const cookie of cookies) {
          //  const [cookieName, cookieValue] = cookie.split('=');
            //if (cookieName === name) {
              //  return cookieValue;
           // }
      //  }
        //return null;
    //};


  //  useEffect(() => {
    //    const accessToken = getCookie('accessToken');
      //  const refreshToken = getCookie('refreshToken');
        //const userId = getCookie('userId');
        // Check if tokens are present, refresh if necessary
   //     if (!refreshToken || !userId) {
     //       navigate('/signin');
      //  }
  //  }, [authData.refreshToken, authData.userId]);

    useEffect(() => {
        // Function to check if the screen size matches the criteria for mobile devices
        const checkIfMobile = () => {
            const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;
            setIsMobile(isMobileDevice);
        };

        // Initial check on component mount
        checkIfMobile();

        // Event listener to check screen size changes
        window.addEventListener('resize', checkIfMobile);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    return (
        <>
            {/* <Signin/> */}
            {/* <Signup/> */}
            <SessionProvider>
                {!isMobile && <RightPanel />}
                {!isMobile && <Sidebar />}
                <Sidebar />
                <Navbar />
                <Dashboard />

                {/* {isSidebarOpen && <SupportSidebar />} */}
                {/* {isMobile && <Dashboard />} */}
            </SessionProvider>
        </>
    );
}

export default MainApp;
