import React, { useState,useEffect } from 'react';
import { useSessionContext } from '../context/SessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faClock, faCopy, faDesktop, faEnvelope, faIdBadge, faLink, faMessage, faNoteSticky, faTerminal } from '@fortawesome/free-solid-svg-icons';
import './RightPanel.css';

const RightPanel = () => {
    const { session, isSessionClicked, setIsSessionClicked, index } = useSessionContext();

    const [selectedOption, setSelectedOption] = useState(null);
    const [email, setEmail] = useState('');
    const [customMessage, setCustomMessage] = useState('');
    const [shareableLink, setShareableLink] = useState('https://example.com');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCustomMessageChange = (e) => {
        setCustomMessage(e.target.value);
    };


    const handleGenerateLink = () => {
        // Add logic to generate shareable link
        const generatedLink = 'https://example.com'; // Replace with actual logic
        setShareableLink(generatedLink);
    };

    const handleCopyLink = () => {
        // Add logic to copy link to clipboard
        const dummyLink = shareableLink;
        // You can use document.execCommand('copy') or a library like clipboard.js to copy the link
        console.log('Link copied:', dummyLink);
    };

    const handleSendEmail = () => {
        // Add logic to send email
        console.log('Sending email to:', email);
    };

    const renderContent = () => {
        switch (selectedOption) {
            case 'Code':
                return (
                    <div className="px-5 py-4 text-white">
                        <p className='pt-2'>Instruct the user to enter this code:</p>
                        <h1 className="text-4xl font-semibold mt-2">42947</h1>
                        <br />
                        <p className='pt-2'>Direct the user to:</p>
                        <h1 className="text-xl font-semibold mt-2">https://dhruvdua1234.screenconnect.com/</h1>
                    </div>
                );
            case 'Email':
                return (
                    <div className="px-5 py-4 text-white">
                        <p>Enter guest mail id to send link:</p>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="border border-gray-300 rounded-md px-3 py-1 mt-2 mr-4 text-night"
                            placeholder="Enter email"
                        />
                        <textarea
                            value={customMessage}
                            onChange={handleCustomMessageChange}
                            className="border border-gray-300 rounded-md px-3 py-2 mt-2 w-full h-16 text-night"
                            placeholder="Enter custom message to invite"
                        ></textarea>
                        <button
                            onClick={handleSendEmail}
                            className="px-6 py-2 my-1 bg-auburn text-white rounded-md shadow-md hover:bg-umber focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            Send
                        </button>
                    </div>
                );
            case 'Link':
                return (
                    <div className="px-5 py-4 text-white">
                        <p>Shareable link:</p>
                        <div className="flex items-center mt-2">
                            <input
                                type="text"
                                value={shareableLink}
                                readOnly
                                className="border text-night border-gray-300 rounded-md px-3 py-1 w-2/3 mr-2"
                            />
                            <FontAwesomeIcon icon={faCopy} onClick={handleCopyLink} className='cursor-pointer' />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <aside className="fixed top-0 right-0 z-50 pt-16 w-1/3 h-screen bg-night border-l border-gray-200">
            <div className="custom-grid">
                {/* First column for icons */}
                <div className="p-4 bg-night text-gray-500 dark:text-gray-400 w-16">
                    <ul className="space-y-4">
                        <li>
                            <FontAwesomeIcon icon={faDesktop} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Code')} />
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCircleInfo} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Link')} />
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faClock} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Link')} />
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faMessage} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Link')} />
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faTerminal} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Link')} />
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faNoteSticky} className="text-white text-lg cursor-pointer" onClick={() => handleOptionClick('Link')} />
                        </li>
                    </ul>
                </div>
                {/* Second column for content */}
                <div className="bg-night text-gray-500 dark:text-gray-400">
                    {session.length === 0 ?
                        <div className="p-4">
                            <p className="text-gray-500 text-lg">Create a session to display</p>
                        </div> :
                        <>  {isSessionClicked ? <>
                            <div className="border-b border-gray-200 dark:border-gray-700">
                                <h2 className="text-2xl font-semibold mx-4 my-2 text-white">Name: {session.length > 0 ? session[index].name : ''}</h2>
                                <h3 className="inline-block text-white px-5 py-4 rounded-t-lg">Invite Via:</h3>
                                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                                    <li className="me-2">
                                        <button onClick={() => handleOptionClick('Code')} className="inline-flex items-center justify-center p-4 border-b-2 text-white border-transparent rounded-t-lg active hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                            <FontAwesomeIcon icon={faIdBadge} className="text-white mr-2 text-lg" />
                                            Code
                                        </button>
                                    </li>
                                    <li className="me-2">
                                        <button onClick={() => handleOptionClick('Email')} className="inline-flex items-center justify-center p-4 border-b-2 text-white border-transparent rounded-t-lg active hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                            <FontAwesomeIcon icon={faEnvelope} className="text-white mr-2 text-lg" />
                                            Email
                                        </button>
                                    </li>
                                    <li className="me-2">
                                        <button onClick={() => handleOptionClick('Link')} className="inline-flex items-center justify-center p-4 border-b-2 text-white border-transparent rounded-t-lg active hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                            <FontAwesomeIcon icon={faLink} className="text-white mr-2 text-lg" />
                                            Link
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </>
                            : <div className="p-4">
                                <p className="text-gray-500 text-lg">Click on a session to display content</p>
                            </div>}

                            {renderContent()}
                        </>
                    }
                </div>
            </div>
        </aside>
    );
};

export default RightPanel;
