import axios from 'axios';

const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return null;
};


const userId = getCookie("userId");
const accessToken = getCookie("accessToken");
const refreshToken = getCookie("refreshToken");
const api = axios.create({
    baseURL: 'http://localhost:8000',
    timeout: 10000,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    },
});

api.interceptors.request.use(async req => {
    console.log(accessToken);
    if (!accessToken) { 
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    // console.log(req.headers.Authorization);
    // console.log(refreshToken);
    const response = await axios.post(
        "http://localhost:8000/api/auth/access",
        {},
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );

    console.log("response from interceptor access", response);
    return req;
})

api.interceptors.response.use(response => response
    , async (error) => {
        if (error.response.status === 401) {
            const refreshResponse = await axios.post("http://localhost:8000/api/auth/refreshAccessToken", { userId, refreshToken }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (refreshResponse.status === 201) {
                const newAccessToken = refreshResponse.data.accessToken;
                if (newAccessToken) {
                    document.cookie = `accessToken=${newAccessToken}; SameSite=None; Secure`;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                }
                return axios(error.config);
            }
        }
        return Promise.reject(error);
    });

export default api;