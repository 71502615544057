import { useState } from 'react';
import api from '../../services/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

export const Signin = () => {
    const navigate = useNavigate();
    const { setAuthData, isLoading, setIsLoading } = useAuth();
    const [formData, setFormData] = useState({
        email: 'dhruvduatest11@gmail.com',
        password: 'test11'
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSignin = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const response = await axios.post('http://localhost:8000/api/auth/signin', formData);
            document.cookie = `accessToken=${response.data.accessToken}; SameSite=None; Secure`;
            document.cookie = `refreshToken=${response.data.refreshToken}; SameSite=None; Secure`;
            document.cookie = `userId=${response.data.userId}; SameSite=None; Secure`;
          
            setAuthData({
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
                userId: response.data.userId
            })

            navigate('/');
        } catch (error) {
            console.error('Error signing in:', error.response);
        }
    };


    // Function to refresh tokens
    async function refreshTokens() {
        try {
            const accessToken = getCookie('accessToken');
            const refreshToken = getCookie('refreshToken');
            const userId = getCookie('userId');
            // Send POST request to access API
            const response = await api.post('/api/auth/access', { accessToken, refreshToken, userId });
            console.log("response", response);
            // if (response.ok) {
            //     const newData = await response.json();

            //     // Update tokens in cookies with new data
            //     document.cookie = `accessToken=${newData.accessToken}; SameSite=None; Secure`;
            //     document.cookie = `refreshToken=${newData.refreshToken}; SameSite=None; Secure`;
            //     document.cookie = `userId=${newData.userId}; SameSite=None; Secure`;
            // } else {
            //     throw new Error('Failed to obtain tokens');
            // }
        } catch (error) {
            console.error('Error refreshing tokens:', error.message);
            throw error; // Propagate the error for handling in the calling function
        }
    }

    // Function to retrieve a cookie by name
    function getCookie(name) {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return cookieValue;
            }
        }
        return null;
    }


    return (
        <section className="bg-white">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-night rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl text-center font-bold leading-tight tracking-tight  md:text-2xl text-white">
                            Sign in to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSignin}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                                <input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Password</label>
                                <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <button type="submit" className="w-full text-white bg-umber hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                            <p className="text-sm font-light text-gray-300">
                                Don't have an account? <Link to="/signup" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up here</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};


