import { React, useState, useEffect } from 'react';
import { Session } from './Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faEdit, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Temp } from './temp';
import { useSessionContext } from '../context/SessionContext';
import { Delete } from './modals/Delete';


export const Dashboard = () => {

    const { isSupportSidebarOpen,isMeetingSidebarOpen, setSession, session } = useSessionContext();


    const [selectAll, setSelectAll] = useState(false);
    // const [session, setsession] = useState([{ id: 1, host: "host", user: "user" }]);
    const [selectedSessions, setSelectedSessions] = useState([]);

    const createSession = () => {
        const newSession = { id: session.length + 1, isChecked: false, name: "IQ30", host: "host", user: "user" };
        setSession([...session, newSession]);
        // addSession(newSession);
    };

    // Function to handle select all checkbox
    const handleSelectAll = () => {
        if (session.length === 0) {
            setSelectAll(false);
        } else {

            setSelectAll(!selectAll);
            for (let i = 0; i < session.length; i++) {
                session[i].isChecked = !selectAll;
            }

            if (!selectAll) {
                const sessionIds = session.map(session => session.id);
                setSelectedSessions(sessionIds);
            } else {
                setSelectedSessions([]);
            }
            console.log("session bye", session);
        }
    };

    const handleJoin = () => {
        // Handle join button click
    };

    const handleEdit = () => {
        // Handle edit button click
    };

    const handleSessionSelect = (updatedSession) => {
        setSelectAll(false);
        console.log("session", session)
        const updatedSessions = session.map(sess => {
            if (sess.id === updatedSession.id) {
                return updatedSession;
            }
            return sess;
        });
        setSession(updatedSessions);

        if (updatedSession.isChecked) {
            setSelectedSessions([...selectedSessions, updatedSession.id]);
            // console.log("selectedSessions true",selectedSessions)
        } else {
            // console.log("isChecked false")
            setSelectedSessions(selectedSessions.filter(sessionId => sessionId !== updatedSession.id));
            // console.log("selectedSessions false",selectedSessions)
        }
    };


    // const handleDelete = () => {

    //     const updatedSessions = session.filter(session => !selectedSessions.includes(session.id));
    //     setSession(updatedSessions);
    //     setSelectedSessions([]);

    //     if (updatedSessions.length === 0) {
    //         setSelectAll(false); // Untick the "Select All" checkbox
    //     }
    // };

    const handleMore = () => {
        // Handle more button click
    };

    
    return (
        <div className={`${isSupportSidebarOpen || isMeetingSidebarOpen ? 'sm:ml-120 lg:max-w-[44%]' : 'sm:ml-36 lg:max-w-[59%]'} bg-white min-h-screen  `}>
            <div className="mt-14 bg-white mx-4 pt-4 flex-col ">
                <Temp createSession={createSession} />
                <div className="rounded-md bg-white shadow-md p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 mr-2"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            <h2 className="text-night sm:text-lg md:text-xl md:font-semibold mb-0">All Sessions</h2>
                        </div>
                        <div className="flex space-x-2">
                            <button onClick={handleJoin} className="px-2 py-1  text-night rounded-md">
                                <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                                Join
                            </button>
                            <button onClick={handleEdit} className="px-2 py-1 text-night rounded-md">
                                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                Edit
                            </button>
                            <Delete setSelectAll={setSelectAll} selectedSessions={selectedSessions} setSelectedSessions={setSelectedSessions} >
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            </Delete>
                            {/* <button onClick={handleDelete} className="px-2 py-1 text-night rounded-md">
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                <Delete />
                                Delete
                            </button> */}
                            <button onClick={handleMore} className="px-2 py-1  text-night rounded-md">
                                <FontAwesomeIcon icon={faEllipsisH} className="mr-2" />
                                More
                            </button>
                        </div>
                    </div>
                    <div className="gap-4">
                        {session.map((session,idx) => (
                            <Session key={idx} idx={idx} session={session} isChecked={selectAll || session.isChecked} onSelect={handleSessionSelect} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
        // )
    );
};
